import React, { useState, useEffect } from "react";
import api from "../services/api";
import "../pages/newsDetail.less";
import SEO from "../components/seo";

import { Link, } from 'gatsby';

const NewsDetail = (props: any) => {
  //获取接口数据
  const [newsData, setNewsData] = useState({
    author: '',
    releaseTime: '',
    title: '',
    content: '',
    typeName: ''
  });
  const fetchNewsData = async () => {
    try {
      const dictRequest = api.post('/api/app/dict/info/data', { types: 'articleType' });
      const response = await api.get(`/api/app/article/info/info?id=${props.location.search.split('=')[1]}`);
      const dict = await dictRequest;
      const newsData = response.data.data;
      const type = dict.data.data.articleType.find((item: { id: number; }) => item.id === newsData.typeDict);
      newsData.typeName = type ? type.name : '资讯详情';
      setNewsData(newsData);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchNewsData();
  }, []);

  return (
    <div className='newsDetail'>
      <div className="titles"></div>
      <div className="newsDetail-title">
        <span>{newsData.typeName}</span>
      </div>
      <div className="detail">
        <div className="main">
          <div className="news-author">作者：{newsData.author}，{newsData.releaseTime}</div>
          <div className="news-title">
            <div className="news-title-text">{newsData.title}</div>
          </div>
          <div className="news-html" dangerouslySetInnerHTML={{ __html: newsData.content }}></div>
        </div>
      </div>
    </div>
  )
}

export default NewsDetail;

export const Head = () => (<SEO />)